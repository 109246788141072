/** @jsx jsx */
import React from "react"
import { Flex, jsx } from "theme-ui"
import BlogLink from "./BlogLink"

interface PaginationProps {
  pageContext: any
}

interface PageLinkProps {
  children: React.ReactNode
  to: string
}

const PageLink = (props: PageLinkProps) => {
  const { children, to } = props

  return (
    <BlogLink to={to}>
      <span
        sx={{
          fontSize: [1,3],
          mr: 4,
          textDecoration: "none",
        }}
      >
        {children}
      </span>
    </BlogLink>
  )
}

const getPageLink = i => {
  if (i === 1) return "/blog"
  return `/blog/${i}`
}

const Pagination = (props: PaginationProps): JSX.Element => {
  const { pageContext } = props
  const {
    humanPageNumber,
    numberOfPages,
    previousPagePath,
    nextPagePath,
  } = pageContext

  let pages: JSX.Element[] = []

  for (let i = 1; i <= numberOfPages; i++) {
    pages.push(
      <PageLink to={getPageLink(i)}>
        <span
          sx={{
            color: i === humanPageNumber ? "text" : "accent",
          }}
        >
          {i}
        </span>
      </PageLink>
    )
  }

  return (
    <Flex>
      {previousPagePath && <PageLink to={previousPagePath}>Prev</PageLink>}
      {pages}
      {nextPagePath && <PageLink to={nextPagePath}>Next</PageLink>}
    </Flex>
  )
}

export default Pagination
