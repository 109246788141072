/** @jsx jsx */
import React from "react"
import { Box, Text, Flex, Grid, Heading, jsx } from "theme-ui"
import { PostInterface } from "../../types/post"
import CategorySubLink from "./CategorySubLink"
import BlogLink from "./BlogLink"
import SharePost from "./SharePost"
import { getPostLink } from "../../utils/post"

interface PostItemProps {
  post: PostInterface
}

const PostItem = (props: PostItemProps): JSX.Element => {
  const { post } = props

  return (
    <Box mb={4}>
      <Text variant="secondary">
        {post.categories.map((category, index) => (
          <CategorySubLink key={index} category={category} />
        ))}
      </Text>
      <BlogLink to={getPostLink(post)}>
        <Heading mb={4} sx={{ fontSize: [1, 4] }}>
          {post.title}
        </Heading>
      </BlogLink>
      <Grid columns={[1, "1fr auto"]} gap={4}>
        <BlogLink to={getPostLink(post)}>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <img
              sx={{ maxWidth: "150px" }}
              src={post.image.url + "&w=150"}
              alt={post.image.alt}
            />
          </Box>
        </BlogLink>
        <Box>
          <Text sx={{ fontSize: [0, 1] }}>{post.description}</Text>
          <Text sx={{ fontSize: [0, 1] }} mt={2} variant="secondary">
            {post.date} - 4 min read
          </Text>
          <Flex mt={2} sx={{ justifyContent: "flex-end" }}>
            <SharePost post={post} />
          </Flex>
        </Box>
      </Grid>
    </Box>
  )
}

export default PostItem
