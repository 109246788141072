import React from "react"
import { Box, Text, Grid } from "theme-ui"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import PostItem from "../components/Blog/PostItem"
import RecentPosts from "../components/Blog/RecentPosts"
import Categories from "../components/Blog/Categories"
import Pagination from "../components/Blog/Pagination"
import { graphql } from "gatsby"
import { PostInterface } from "../types/post"
import { getPosts } from "../utils/post"

export const pageQuery = graphql`
  query BlogPage($skip: Int!, $limit: Int!) {
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          url
          data {
            title {
              text
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      name
                    }
                  }
                }
              }
            }
            content {
              raw
            }
            date(formatString: "MMM D, YYYY")
            main_image {
              alt
              url
            }
            description {
              text
            }
          }
        }
      }
    }
  }
`

const Blog = ({ data, pageContext }) => {
  const posts: PostInterface[] = getPosts(data)

  return (
    <Box>
      <SEO title="Blog" />
      <Layout>
        <PageHeader>
          <Text sx={{ fontSize:[3,5], fontWeight: "bold", textAlign: "center" }}>
            Blog
          </Text>
        </PageHeader>
        <Grid
          mb={[-4,6]}
          mt={[2,5]}
          sx={{ maxWidth: "1100px", mx: "auto" }}
          px={3}
          columns={[1, 1, 1, "1fr auto"]}
        >
          <Box px={[2, null, 3, 4]}>
            {posts.map((post, index) => (
              <PostItem key={index} post={post} />
            ))}
            <Pagination pageContext={pageContext} />
          </Box>
          <Box>
            <RecentPosts />
            <Categories />
          </Box>
        </Grid>
      </Layout>
    </Box>
  )
}

export default Blog
